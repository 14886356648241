.LabelBox{
    padding: 28px 15px !important;
    font-size: 14px;
}
.FormBox{
    padding: 25px 25px;
    margin-top: 0 !important;
    background-color: #F7F7F7;
    border: 1px solid #e5e5e5;
}
.FormGroup{
    padding: 0 30px;
}

/* .Max{
max-width: 650px !important;
} */