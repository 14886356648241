.TableRow{
 cursor: pointer;
}
.TableRow:hover{
    background-color: #f7f7f7 !important;
}
.Selected{
    background-color: #e7e7e7;
}
.errorLabel{
    color: #ff0000;
    text-align: center;
    padding: 10px 0;
    width: 100%;
    font-size: 14px;
}