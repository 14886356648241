.LabelBox {
  padding: 28px 15px !important;
  font-size: 14px;
}
.FormBox {
  padding: 25px 25px;
  margin-top: 0 !important;
  background-color: #f7f7f7;
  border: 1px solid #e5e5e5;
}
.FormGroup {
  padding: 0 30px;
}

/* .Max{
max-width: 650px !important;
} */
.ErrorMsg {
  font-size: 12px;
  color: #ff0000;
  margin-bottom: 8px;
  margin-top: 4px;
  padding-left: 5px;
}
.ErrorIcon {
  color: #ff0000;
  font-size: 20px;
  margin-top: 11px;
}

/* .Max{
  max-width: 650px !important;
  } */

  
