.FormBox {
  padding: 25px 0;
  margin-top: 0 !important;
  background-color: #f7f7f7;
}
.FormGroup {
  padding: 0 30px;
}
.ErrorMsg {
  font-size: 12px;
  color: #ff0000;
  margin-bottom: 8px;
  margin-top: 4px;
  padding-left: 5px;
}
.ErrorIcon {
  color: #ff0000;
  font-size: 20px;
  margin-top: 11px;
}

.Disabled {
  background-color: #e0e0e0 !important;
  color: #a7a7a7 !important;
}
/* .LabelBox{
    font-family: inherit;
} */

.TextInput {
  width: 100%;
  border: 1.5px solid #e8e8e8;
  border-radius: 14px;
  padding: 17px 22px;
  outline: none;
  font-size: 16px !important;
}
