
.FormBox{
    padding: 25px 0;
    margin-top: 0 !important;
    background-color: #F7F7F7;
}
.FormGroup{
    padding: 0 30px;
}
.ErrorMsg{
    font-size: 12px;
    color: #FF0000;
    margin-bottom: 8px;
    margin-top: 4px;
    padding-left: 5px;
}
.ErrorIcon{
    color: #FF0000;
    font-size: 20px;
    margin-top: 11px;
}
.RadioLabel{
    font-size: 10px !important;
}
