.Header {
  height: 69px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 102;
}
@media only screen and (max-width: 1200px) {
  .Header {
    padding: 0;
  }
}
.Logo {
  /* padding-top: 19px; */
  width: 132px !important;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.LogoImg {
  max-height: 70px !important;
  padding: 10px 0px;
}

.Icon {
  color: #fff !important;
  font-size: 25px;
}
.arrow {
  font-size: 16px;
}
.Link {
  color: #fff !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  text-transform: uppercase;
  text-decoration: none !important;
  cursor: pointer;
}

.Box {
  padding: 3px 9px;
  color: #ffffff;
  font-weight: 700;
  height: 40px;
}

.DataHeader {
  background-color: #f7f7f7;
  font-size: 14px;
  height: 0px;
  font-weight: 400 !important;
}

.ButtonIcon {
  font-size: 14px !important;
  -webkit-text-stroke: 2px black;
}

.ButtonIconPlay {
  font-size: 14px !important;
  -webkit-text-stroke: 2px black;
  margin-right: 10px !important;
}

.IconPlay {
  font-size: 14px !important;
  -webkit-text-stroke: 2px black;
  margin-top: 5px !important;
  margin-bottom: 5px !important;

  margin-left: 12px !important;
}

.MenuIcon {
  font-size: 35px;
  color: #fff;
}

.MenuGrid {
  height: 69px;
}

.LabelBox {
  padding: 0px 0px 25px 0px !important;
  font-size: 14px;
}
.FormBox {
  padding: 15px 15px;
  margin-top: 0 !important;
  background-color: #f7f7f7;
  border: 1px solid #e5e5e5;
}
.FormGroup {
  padding: 0 30px;
}
