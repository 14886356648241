.FormBox {
  padding: 25px 0;
  margin-top: 0 !important;
  background-color: #f7f7f7;
}
.FormGroup {
  padding: 0 30px;
}
.ErrorMsg {
  font-size: 12px;
  color: #ff0000;
  margin-bottom: 8px;
  margin-top: 4px;
  padding-left: 5px;
}
.ErrorIcon {
  color: #ff0000;
  font-size: 20px;
  margin-top: 11px;
}

.MuiSelect-select {
  height: 0.4em !important;
  min-height: 0.7em !important;
}

.PublicSelect {
  width: 100% !important;
  border: 1.5px solid #e8e8e8;
  outline: none !important;
  border-radius: 14px !important;
  font-size: 16px !important;
  background-color: #fff !important;
  padding: 6px 10px;
}

.OptionPrimary {
  font-size: 16px;
}
