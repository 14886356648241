.FormBox {
  padding: 25px 0;
  margin-top: 0 !important;
  background-color: #f7f7f7;
}
.FormGroup {
  padding: 0 30px;
}
.ErrorMsg {
  font-size: 12px;
  color: #ff0000;
  margin-bottom: 8px;
  margin-top: 4px;
  padding-left: 5px;
}
.ErrorIcon {
  color: #ff0000;
  font-size: 20px;
  margin-top: 11px;
}

.Disabled {
  background-color: #e0e0e0;
  color: #a7a7a7;
}
.ListBox {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  border: 1.5px solid #e8e8e8;
  overflow: scroll;
}

.ListItems {
  width: 100%;
  padding: 0 !important;
}
.ListItemText {
  font-size: 14px !important;
}
.ListItemMore {
  font-size: 14px !important;
  color: #1d6cff !important;
}
.ContentCenter {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Error {
  color: #ff0000;
}
.ListWithLines {
  border-bottom: 1.5px solid #e8e8e8;
}
.Selected {
  background-color: rgb(241, 241, 241) !important;
}
