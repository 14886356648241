.ActionButton button{
    /* background-color: #615586 !important; */
    border: none;
    outline: none;
    border-radius: 0;
    font-size: 14px !important;
}

.Icon{
    margin-right: 10px !important;
    font-size: 14px;
}