.Icon{
    font-size: 45px;
    font-weight: 800;
    color: #fff;
}
.Circle{
    padding: 30px;
    background-color: aqua;
    margin-bottom: 25px;
    border-radius: 100%;
    background: linear-gradient(180deg, #615586 0%, #AA95D1 100%);
}

.SmallCircle{
    padding: 15px;
    background-color: aqua;
    margin-bottom: 25px;
    border-radius: 100%;
    background: linear-gradient(180deg, #615586 0%, #AA95D1 100%);
}

.SmallIcon{
    font-size: 35px;
    font-weight: 800;
    color: #fff;
}

.Text{
    font-weight: 600;
}