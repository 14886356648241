.Table{
    font-size: 14px;
    overflow: scroll;
    border: none !important;
    
}

.table > :not(caption) > * > *{
    padding:0.2rem 0.2rem !important;
}

.Header{
    background-color: #F7F7F7;
    font-size: 14px;
    height: 60px;
    font-weight: 400 !important;
}

.RowEven{
    background-color: #FFFFFF !important;
} 

.RowOdd{
    background-color: #F7F7F7 !important;
}
.Box{
    background-color: #B4A6CB;
    padding: 15px  25px;
    color: #FFFFFF;
    font-weight: 700;
}
.Ul{
    display: inline-grid;
  grid-template-columns: 50px 50px 50px 50px;
  border: 1px solid black;
  grid-gap: 1px;
  background-color: black;
}

.disabled {
    border: none !important;
}