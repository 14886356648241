
.Footer{
   position: fixed;
   left: 0;
   bottom: 0;
   height: 50px;
   width: 100%;
   background-color: #615586;
   color: white;
   padding: 0 50px;
   font-size: 13px;
   z-index: 102;
}