.root {
  font-size: 14px !important;
  font-weight: 600;
}
.subRoot {
  font-size: "14px";
  font-weight: 400;
  color: "#00000099";
}
.subMenu {
  padding-left: "44px" !important;
}
.expandIcon {
  font-size: "20px";
}
