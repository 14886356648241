thead, tbody, tfoot, tr, td, th{
    border: none !important;
    border-style: none !important;
    border-bottom: 1px solid #e5e5e5 !important;
    vertical-align: middle;
}
th{
    font-weight: 600;
}
thead, tbody{
    border-left: 1px solid #e5e5e5 !important;
    border-right: 1px solid #e5e5e5 !important;
}
.table > thead{
    vertical-align: middle !important;
}
.page-item.active .page-link{
    background-color: #314760;
    border-color: #314760;
}
.page-link{
    color: #314760;
}
.react-bootstrap-table-page-btns-ul{
    float:none  !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F0F3EF;
    padding: 15px 0;
    border: 1px solid #e5e5e5;
    border-top: none !important;
}
table{
    margin-bottom: 0 !important;
}


.disabled {
    border: none !important;
}