.App {
  font-family: "Open Sans", sans-serif;
}
Body {
  overflow: scroll;
  overflow: overlay;
}

.InputPrimary {
  width: 100%;
  border: 1.5px solid #e8e8e8;
  padding: 10px 15px;
  outline: none;
  font-size: 13px;
}
.SelectPrimary {
  width: 100% !important;
  border: 1.5px solid #e8e8e8;
  outline: none !important;
  border-radius: 0 !important;
  font-size: 13px !important;
  background-color: #fff !important;
}

.ReduceText {
  width: 550px !important;
}

.ScheduleManagement_daysList__iuJiX {
  margin-bottom: 0rem !important;
}

.page-item.disabled .page-link {
  border-radius: 0px !important;
}

.page-item:last-child .page-link {
  border-radius: 0px !important;
}

.css-177ic5c {
  border-radius: 0px !important;
}

.rmdp-wrapper {
  direction: ltr;
  text-align: center;
  border-radius: 0px !important;
  box-shadow: none !important;
  border: 1px solid #e8e8e8;
}

.MuiModal-root {
  border-radius: 0px !important;
}

.rmdp-panel-body li {
  border-radius: 0px !important;
}

.page-item:first-child .page-link {
  border-radius: 0px !important;
}

/* .tableWidth{
  padding: 15px 11px !important ;
} */

.MuiAutocomplete-listbox .MuiAutocomplete-option {
  font-size: 14px !important;
}

.MuiInputBase-input-MuiOutlinedInput-input {
  font-size: 14px !important;
}

input {
  font-size: 14px !important;
}

/* .MuiAutocomplete-listbox{
  font-size:"14px !important"
  } */

.MuiAutocomplete-option {
  font-size: 14px !important;
}

.OptionPrimary {
  font-size: 13px !important;
}
/* .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
  border: 0 !important;
} */
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px 15px !important;
}

.ErrorBorder {
  border: 1.5px solid #ff0000 !important;
}

fieldset {
  width: unset !important;
}

.LabelBox {
  font-family: "Open Sans", sans-serif;
  padding: 24px 15px !important;
  font-size: 14px;
  font-weight: 600 !important;
}

.LabelOnly {
  font-size: 14px;
  font-weight: 600 !important;
}

.TextField {
  padding: 0px !important;
}

.RadioInput {
  cursor: pointer;
}
.RadioLabel {
  padding-left: 5px;
  font-size: 13px;
  margin-right: 10px;
  position: relative;
  cursor: pointer;
}

input[type="radio"] {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid darkgray;
  border-radius: 150px;
  outline: none;
}

input[type="radio"]:hover {
  box-shadow: 0 0 3px 0px #b4a6cb inset;
}

input[type="radio"]:before {
  content: "";
  display: block;
  width: 70%;
  height: 70%;
  margin: 12% auto;
  border-radius: 150px;
}
input[type="radio"]:checked:before {
  background: #615586;
}
.ActionButton button {
  border: none;
  outline: none;
  border-radius: 0;
  font-size: 14px !important;
}

.disabled {
  background-color: #e9e9e9 !important;
  border: 1.5px solid #d9d9d9;
  -webkit-text-fill-color: rgb(0 0 0 / 30%) !important;
}

/*Scroll bar style*/

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  position: absolute;
}

.MuiSelect-select {
  padding: 10px 15px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b4a6cb;
  transition: 0.8s ease-in-out;
}

.MuiDataGrid-columnSeparator {
  display: none !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #615586;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.BackWhite {
  background-color: white;
}

/*Scroll bar style*/

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  border-radius: 0 !important;
}
.css-5ahc25-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background-color: #b4a6cb !important;
  color: #fff;
}

.MuiPaper-root {
  border-radius: 0 !important;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #e8e8e8 !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #e8e8e8 !important;
}

.MuiInputBase-root-MuiOutlinedInput-root.Mui-error
  .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.menuPaper {
  max-height: 30;
}

.MuiDataGrid-root {
  border-radius: 0px !important;
}

.css-dhopo2 {
  min-height: 230px !important;
}

input:focus {
  outline: none !important;
}

textarea:focus {
  outline: none !important;
}

.input:focus {
  outline: none !important;
}

.item-relative {
  position: relative;
}
.css-14dvey5-MuiTooltip-tooltip {
  background-color: #b4a6cb !important;
  border-radius: 0 !important;
}

/* @media (min-width: 600px){
  .css-vxova4 {
    min-height: 180px !important;
}
} */

.stopwatch {
  width: fit-content;
  background-color: #aa95d1;
  color: white;
  text-align: center;
  padding: 6px 7px;
  margin-left: 10px;
  font-size: 1rem;
}

.editorClassName {
  border: 1px solid #f1f1f1;
  min-height: 400px;
  padding: 5px 20px;
}
.readOnlyEditor {
  border: 1px solid #f1f1f1;
  min-height: 400px;
  padding: 5px 20px;
  background-color: #e5e5e5 !important;
  color: rgba(0, 0, 0, 0.5) !important;
}

.DraftEditor-root {
  height: auto !important;
}

.rdw-dropdown-selectedtext {
  color: inherit !important;
  text-decoration: none !important;
}

.hide-toolbar {
  display: none !important;
}

.pg-viewer-wrapper {
  background-color: #f7f7f7 !important;
  border: 1px solid #e5e5e5 !important;
  max-height: 450px !important;
  overflow: scroll !important;
}

.react-pdf__Document {
  background-color: #f7f7f7 !important;
  border: 1px solid #e5e5e5 !important;
  overflow: scroll;
  height: 450px;
}

.react-pdf__Page {
  margin-top: 10px;
}
.react-pdf__Page__textContent {
  border: 1px solid darkgrey;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}

.all-page-container {
  height: 100%;
  max-height: 500px;
  overflow: auto;
}
.MuiAlert-message {
  padding: 8px 15px 8px 0 !important;
}

.SubSectionRow {
  margin-left: 10px !important;
}

.ActiveSubSection {
  background-color: #f7f7f7;
}

.subSection {
  cursor: pointer;
  margin-top: 10px !important;
}
.subSection:hover {
  background-color: #f7f7f7;
}

@media (min-width: 600px) {
  .MuiSnackbar-root {
    bottom: 69px !important;
    z-index: 9999999999999999999999999;
  }
}
.btn-primary:hover {
  background-color: #aa95d1 !important;
}
.btn-primary:disabled {
  background-color: #e9e9e9 !important;
  border: 1.5px solid #d9d9d9 !important;
  -webkit-text-fill-color: rgb(0 0 0 / 30%) !important;
}

.a-btn {
  background-color: #56438e;
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  padding: 9px 14px;
  cursor: pointer;
}
.a-btn:hover {
  color: #fff;
}
.NotFoundImage {
  width: 80%;
  height: auto;
  background-position: center !important;
  background-size: cover !important;
}
.MuiTypography-root.Mui-selected {
  /* background-color: #56438e !important; */
  border-radius: 0;
}
.MuiTypography-root:hover {
  border-radius: 0;
}

.Mui-selected {
  /* background-color: #56438e !important; */
  border-radius: 0 !important;
}

.css-qkcmu6:hover {
  border-radius: 0 !important;
}

@media print {
  .icon {
    opacity: 0;
    animation: fadeIn 0.1s forwards;
  }
  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
}
