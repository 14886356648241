.Body{
    display: flex;
}
.List{
    background-color: aqua;
}
.ListMenuText{

}

.Logo{
    padding: 19px;
    width: 150px !important;
}
.MenuIcon{
    cursor: pointer;
}

.fixedItem{
position: fixed !important;
}