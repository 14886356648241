.LabelBox{
  padding: 28px 15px !important;
  font-size: 14px;
}
.FormBox{
  padding: 25px 25px;
  margin-top: 0 !important;
  background-color: #F7F7F7;
  border: 1px solid #e5e5e5;
}
.FormGroup{
  padding: 0 30px;
}

.ErrorMsg{
  font-size: 12px;
  color: #FF0000;
  margin-bottom: 8px;
  margin-top: 4px;
  padding-left: 5px;
}
.ErrorIcon{
  color: #FF0000;
  font-size: 20px;
  margin-top: 11px;
}

.daysList{
  list-style-type: none;
  display: inline-table;
  padding-left: 2px;
}

.daysList li{
  display: inline-block;
  padding: 5px;
  margin: 2px;
  color: black;
  border: 1px solid #8e78b7;
}

.Header{
  background-color: #F7F7F7;
  font-size: 14px;
  height: 10px;
  font-weight: 400 !important;
  border-top:1px solid #e5e5e5 !important;
}


.RowEven{
  background-color: #FFFFFF !important;
  height: 60px;
} 

.RowOdd{
  background-color: #F7F7F7 !important;
  height: 60px;

}