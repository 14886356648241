
.IconButton {
    right: 10px;
    top: 12px;
    color: #ff0000;
    font-size: 20px;
    position: absolute;
    cursor: pointer;
  }
  .FileInput{
    position: relative;
  }